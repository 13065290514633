/** @jsx jsx **/
import { jsx } from '@emotion/core';
import { defaultStrings } from '../../lib/i18n/en';

const HelpDeskLink = () => {
	const {helpDeskIntro, systemSupport, call, icomSupportEmail, icomPhoneNumber } = defaultStrings;
	return(
		<div className={'help-desk help'}>
			<h2>{helpDeskIntro}</h2>
      <p>
        {systemSupport}
        <a href={'mailto:servicedesk@icomproductions.ca?subject=CNRL%20Contractor%20Portal%20Support'} target="_blank" rel='noopener noreferrer'>{icomSupportEmail}</a>
        {call}
        <a href={'tel:1-877-275-7190'}>{icomPhoneNumber}</a>
      </p>
		</div>
	)
};


export default HelpDeskLink;
