/** @jsx jsx **/
import { jsx } from '@emotion/core';
import { defaultStrings } from '../../lib/i18n/en';

const CreateEmailHelpLink = () => {
	const {recommendedEmailPartner, gmail, recommendedEmailPartnerPart2, outlook} = defaultStrings;
	return(
		<div className={'email-help help'}>
			<h2>Create an email address</h2>
			<p>{recommendedEmailPartner} <a href={'//www.google.com/intl/en-GB/gmail/about/'}  target="_blank" rel='noopener noreferrer'>{gmail}</a> {recommendedEmailPartnerPart2} <a href={'//outlook.live.com/owa/'} target="_blank" rel='noopener noreferrer'>{outlook}</a>.</p>
		</div>
	)
};


export default CreateEmailHelpLink;
