/** @jsx jsx **/
import { jsx } from '@emotion/core';

const StyledLogoBar = () => ({
  height: '6rem',
  display: 'flex',
  width: '14rem',
  padding: '1rem',
	position: 'relative',
	zIndex: '1',
  '.logo':{
    background: "url('/orgresources/1/FM_Guidebook_Resources/logo.png') 50% 50%",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '6rem',
    margin: 'auto',
    width: '10rem',
  },
	'@media only screen and (max-width: 425px)': {
		height: '8rem',
		margin: 'auto',
	}
});

const LogoBar = () => {
  return(
    <div className={'logo-bar'} css={StyledLogoBar}>
      <div className={'logo'}/>
    </div>
  )
};


export default LogoBar;
