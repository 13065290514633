const checkFormCompleteHelper = (formInputs) => {
	let complete = true;
	formInputs.forEach(input => {
		if(!input.valid){
			complete = false;
		}
	});
	return complete;
};

export default checkFormCompleteHelper
