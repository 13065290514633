/** @jsx jsx **/
import { jsx } from '@emotion/core';

const StyledDescriptionText = () => ({
	width: '100%',
	color: 'rgba(0,0,0,0.6)',
	textAlign: 'center',
	fontSize: '1.2rem',
	marginBottom: '3rem'
});

const DescriptionText = ({text}) => {
	return(
		<div className={'description-text'} css={StyledDescriptionText}>
			<p>{text}</p>
		</div>
	)
};


export default DescriptionText;
