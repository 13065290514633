/** @jsx jsx **/
import { jsx } from "@emotion/core";
import TextInputBase
	from './TextInputBase';

const TextInput = ({ required, setValue, name, ...props }) => {
  const onValueChange = (value) => {
	 if((required && value.length > 0) || !required){
	 	setValue(name, value, true);
	 }else{
	 	setValue(name, value, false)
	 }
  };

  return (
   <TextInputBase required={required} type={'text'} onValueChange={onValueChange} name={name} {...props} />
  );
};

export default TextInput;
