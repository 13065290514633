/** @jsx jsx **/
import { jsx } from '@emotion/core';
import LogoBar
	from './LogoBar';
import {defaultStrings} from '../lib/i18n/en';

const StyledTitleBar = () => ({
	display: 'flex',
	borderBottom: '0.2rem solid #007CC1',
	background: '#fff',
	borderRadius: '0.5rem 0.5rem 0 0',
	'.name-bar':{
		background: "url('/orgresources/1/FM_Guidebook_Resources/tiny-dozer.jpg') 50% 50%",
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		color: 'white',
		height: '7rem',
		width: '100%',
		display: 'flex',
		borderRadius: '0rem 0.5rem 0 0',
	},
  '.name':{
    background: 'rgba(0,0,0,0.5)',
    marginTop: '2.5rem',
    height: '2rem',
    lineHeight: '2rem',
    fontSize: '1.3rem',
	  fontWeight: '600',
    width:'100%',
    paddingLeft: '1rem',
  },
	'@media only screen and (max-width: 425px)': {
		'.name-bar':{
			display: 'none',
		},
	},
  '@media only screen and (max-width: 768px)': {
    '.name-bar':{
      '.name':{
        display: 'none',
      }
    },
  },
});

const TitleBar = () => {
  const {contractorELearning} = defaultStrings;
  return(
    <div className={'title-bar'} css={StyledTitleBar}>
	    <LogoBar/>
	    <div className={'name-bar'}>
		    <div className={'name'}>{contractorELearning}</div>
	    </div>
    </div>
  )
};


export default TitleBar;
