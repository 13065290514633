/** @jsx jsx **/
import { jsx } from "@emotion/core";
import { useState } from "react";
import Icon from "../../lib/Icon";

const StyledTextInput = (hasFocus, hasValue, isValid) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  position: "relative",
  fontSize: "1rem",
  paddingBottom: "1.8rem",
  color: "rgba(0,0,0,0.3)",
  input: {
    fontSize: "1.3rem",
    borderRadius: "0.5rem",
    background: "#d4d4d4",
    border: "0.1rem solid transparent",
    padding: "0.5rem",
    transition: "border-color 150ms linear",
    width: "90%",
    margin: "auto",
    "&:focus": {
      outline: "none",
      borderBottom: "0.1rem solid #007CC1"
    },
    "&:disabled": {
      background: "#b0b0b0",
      cursor: "no-drop"
    }
  },
  label: {
    width: "100%",
    p: {
      position: "absolute",
      left: "0.5rem",
      top: hasFocus || hasValue ? "-2.2rem" : "-0.7rem",
      color: hasFocus || hasValue ? "#007CC1" : "rgba(0,0,0,0.6)",
      fontSize: hasFocus || hasValue ? "1rem" : "1.3rem",
      transition: "all 150ms linear",
      pointerEvents: "none"
    }
  },
  ".indicator": {
    position: "absolute",
    lineHeight: "3.4rem",
    right: "0.5rem",
    svg: {
      height: "1.5rem",
      width: "1.5rem",
      path: {
        fill: isValid ? "green" : "red"
      }
    }
  },
  ".info": {
    width: "100%",
    minHeight: "0.8rem",
    fontSize: "0.8rem",
    color: "grey",
    marginLeft: "0.3rem"
  }
});

const TextInputBase = ({
  required,
  disabled,
  type,
  value,
  onValueChange,
  valid,
  name,
  loading,
  info
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  return (
    <div
      className={"text-input-base"}
      css={StyledTextInput(hasFocus, value !== "", valid)}
    >
      <label>
        <p>{name + (required ? "*" : "")}</p>
        <input
          className={"text-input"}
          type={type}
          name={name}
          value={value}
          onFocus={() => setHasFocus(true)}
          onBlur={() => {
            setHasFocus(false);
            onValueChange(value.trim());
          }}
          required={required}
          disabled={loading || disabled}
          onChange={e => {
            onValueChange(e.target.value);
          }}
        />
      </label>
      <div className={"indicator"}>
        <Icon name={valid ? "valid" : "invalid"} />
      </div>
      {<span className={"info"}>{info}</span>}
    </div>
  );
};

export default TextInputBase;
