/** @jsx jsx **/
import { jsx } from '@emotion/core';
import Background from './background/Background';
import ContentBox
  from './ContentBox';

const StyledApp = () => ({
  position: 'relative',
});

const  App = () => {
  return (
    <div css={StyledApp} className="app">
      <Background/>
      <ContentBox/>
    </div>
  );
};

export default App

