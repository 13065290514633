/** @jsx jsx **/
import { jsx } from '@emotion/core';
import Loading
	from './lib/Loading';

const StyledVerificationLoading = theme => ({
	width: '100%',
	height: '100%',
	textAlign: 'center',
	color: 'grey',
	fontSize: '1.2rem',
	padding: '2rem',
	margin: 'auto',
	position: 'relative',
});

const VerificationProgress= () => {
	return(
		<div css={StyledVerificationLoading}>
			<Loading/>
		</div>
	)
};


export default VerificationProgress;
