/** @jsx jsx **/
import { jsx } from '@emotion/core';
import { defaultStrings } from '../lib/i18n/en';

const StyledVerificationSentNotice = theme => ({
	width: '100%',
	height: '100%',
	textAlign: 'center',
	color: 'grey',
	fontSize: '1rem',
	padding: '2rem',
	margin: 'auto',
	'.notice':{
		padding: '1rem 0rem',
	}
});

const VerificationSentNotice = () => {
	const {verificationSentNotice, verificationSentNotice1, verificationSentNotice2} = defaultStrings;
	return(
		<div css={StyledVerificationSentNotice}>
			<div className={'notice'}>
				{verificationSentNotice}
			</div>
			<div  className={'notice'}>
				{verificationSentNotice1}
			</div>
			<div  className={'notice'}>
				{verificationSentNotice2}
			</div>
		</div>
	)
};


export default VerificationSentNotice;
