/** @jsx jsx **/
import { jsx } from "@emotion/core";
import { useState } from "react";
import Form from "./Form";
import { defaultStrings } from "../../lib/i18n/en";
import BasicLogin from "../../actions/basicLogin";
import PostLogin
	from '../../actions/postLogin';

const PasswordForm = ({ previousDetails }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const {
    enterPortal,
    welcomeBack,
    email,
    password,
    loginCredentialsDoNotMatchRecords
  } = defaultStrings;
  const [formInputs, setFormInputs] = useState([
    {
      name: email,
      type: "text",
      value: previousDetails["Email"] ? previousDetails["Email"] : "",
      valid: true,
      disabled: true,
      required: false
    },
    {
      name: password,
      type: "password",
      value: "",
      valid: false,
      disabled: false,
      required: true
    }
  ]);

  const onComplete = () => {
    setLoading(true);
    setMessage('');
    BasicLogin(formInputs[0].value, formInputs[1].value).then(response => {
      setLoading(false);
      if(response.ok){
        PostLogin();
      }else{
        setMessage(loginCredentialsDoNotMatchRecords);
      }
    })
  };

  return (
    <Form
      formInputs={formInputs}
      setFormInputs={setFormInputs}
      submitText={enterPortal}
      action={onComplete}
      loading={loading}
      description={welcomeBack}
      message={message}
    />
  );
};

export default PasswordForm;
