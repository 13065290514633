/** @jsx jsx **/
import { jsx } from '@emotion/core';
import TitleBar
  from './TitleBar';
import AdminLoginView
	from './views/AdminLoginView';
import LandingPage
	from './views/LandingPage';
import {
	useEffect,
	useState
} from 'react';
import PasswordView
	from './views/PasswordView';
import UserDetailsView
	from './views/UserDetailsView';
import CompanySelectView
	from './views/CompanySelectView';
import VerificationSentView
	from './views/VerificationSentView';
import VerificationLoading
	from './views/VerificationLoading';

const StyledContentBox = () => ({
  width: '100%',
  display: 'flex',
	zIndex: '1',
	position: 'relative',
	paddingBottom: '3rem',
  '.content':{
    margin: 'auto',
	  marginTop: '9rem',
    minWidth: '45rem',
    minHeight: '20rem',
	  borderRadius: '0.5rem',
    background: '#c2c2c2',
    border: '0.3rem solid rgba(0,0,0,0)',
	  boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0.125rem 0.625rem 0px',
	  display: 'flex',
	  flexDirection: 'column'
  },
	'@media only screen and (max-width: 768px)':{
		'.content':{
			minWidth: '100%',
			minHeight: '100%',
			background: '#c2c2c2',
			border: '0.3rem solid rgba(0,0,0,0)',
			boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0.125rem 0.625rem 0px',
			display: 'flex',
			flexDirection: 'column',
			margin: '0',
		},
	}
});

const ContentBox = () => {
	const [currentView, setCurrentView] = useState('Landing');
	const [previousDetails, setPreviousDetails] = useState({});

	const views = {
		Admin: AdminLoginView,
		Landing:LandingPage,
		Password: PasswordView,
		Details: UserDetailsView,
		Company: CompanySelectView,
		VerificationSent: VerificationSentView,
		VerificationInProgress: VerificationLoading
	};
	const Content = views[currentView];

	useEffect(() => {
		const urlString = window.location;
		const url = new URL(urlString);
		const token = url.searchParams.get('token');
		if(token !== null){
			setPreviousDetails({...previousDetails, token:token});
			setCurrentView('VerificationInProgress');
		}
	}, [window.location]);

  return(
    <div className={'content-box'} css={StyledContentBox}>
      <div className={'content'}>
        <TitleBar/>
        <Content setView={setCurrentView} previousDetails={previousDetails} setPreviousDetails={setPreviousDetails}/>
      </div>
    </div>
  )
};


export default ContentBox;
