import PostLogin from "./postLogin";

const mergeUsers = (userDetails, setMessage, internalError) =>
  fetch("/includes/modules/FM_LoginApis/cnrlContractor/checkMigrationDetails.php", {
    method: "POST",
    body: JSON.stringify(userDetails)
  }).then(response => {
    if (response.ok) {
      return PostLogin();
    } else {
      setMessage(internalError);
    }
  });

export default mergeUsers;
