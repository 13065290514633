/** @jsx jsx **/
import { jsx } from '@emotion/core';
import { defaultStrings } from '../../lib/i18n/en';

const StyledBadgeHelp = () => ({
	'.badge-example':{
		background: "url('/orgresources/1/FM_Guidebook_Resources/badge-blue-final.png') 50% 50%",
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		width: '100%',
		height: '14rem',
	}
});


const BadgeNumber = () => {
	const {whatsMyBadgeNumber, badgeNumberLocation} = defaultStrings;
	return(
		<div className={'help-desk help'} css={StyledBadgeHelp}>
			<h2>{whatsMyBadgeNumber}</h2>
			<p>{badgeNumberLocation}</p>
			<div className={'badge-example'}/>
		</div>
	)
};


export default BadgeNumber;
