const ResetPassword = (username) =>
	fetch("/includes/modules/FM_LoginApis/cnrlContractor/passwordReset.php", {
		method: "POST",
		body: JSON.stringify({ username: username })
	}).then(response => {
		if(response.ok){
		}else{
			return {login:false, message:'Internal Error Occurred. Please try again'}
		}
	});

export default ResetPassword;
