/** @jsx jsx **/
import { jsx } from "@emotion/core";
import TextInputBase
	from './TextInputBase';

const PasswordInput = ({ required, setValue, name, ...props }) => {
  const onValueChange = (value) => {
	 if(value.length > 0){
	 	setValue(name, value, true);
	 }else{
	 	setValue(name, value, false)
	 }
  };

  return (
   <TextInputBase required={required} type={'password'} onValueChange={onValueChange} name={name} {...props} />
  );
};

export default PasswordInput;
