/** @jsx jsx **/
import { jsx } from "@emotion/core";
import SubmitButton from "../forms/form-inputs/SubmitButton";
import Input from "./form-inputs/Input";
import { useEffect, useState } from "react";
import checkFormCompleteHelper from "../../lib/checkFormCompleteHelper";
import setValueHelper from "../../lib/setValueHelper";
import DescriptionText from "../lib/DescriptionText";
import Loading from "../lib/Loading";

const StyledForm = loading => ({
  width: "100%",
  padding: "2.5rem 1rem 1rem 1rem",
  position: "relative",
  background: loading ? "aliceblue" : "transparent"
});

const Form = ({
  formInputs,
  setFormInputs,
  submitText,
  action,
  description,
  loading,
	message = '',
	...props
}) => {
  const [isComplete, setIsComplete] = useState(false);
  useEffect(
    () => {
      setIsComplete(checkFormCompleteHelper(formInputs));
    },
    [formInputs]
  );

  return (
    <form className={"form-element"} css={StyledForm(loading)}>
      <DescriptionText text={description} />
      {formInputs.map(input => (
        <Input
          key={input.name}
          input={input}
          setValue={setValueHelper(formInputs, setFormInputs)}
          loading={loading}
          {...props}
        />
      ))}
	    <p>{message}</p>
      <SubmitButton
        disabled={loading || !isComplete}
        text={submitText}
        action={action}
        loading={loading}
      />
      {loading ? <Loading loading={loading} /> : null}
    </form>
  );
};

export default Form;
