/** @jsx jsx **/
import { jsx } from '@emotion/core';
import HelpDeskLink
	from '../help-links/HelpDeskLink';
import AdminLoginLink
	from '../help-links/AdminLoginLink';
import VerificationSentNotice
	from '../VerificationSentNotice';
import BackLink
	from '../help-links/BackLink';

const StyledEmailInputPage = () => ({
	height: '100%',
	background: 'white',
	borderRadius: '0 0 0.3rem 0.3rem',
	position: 'relative',
	width: '45rem',
	'.body':{
		display: 'flex',
		minHeight: '20rem',
		'.help-links':{
			padding: '1.5rem 1rem 1rem 1rem',
			borderLeft: '0.2rem solid #007CC1',
			width: '20rem',
			'.help':{
				padding: '0.3rem 0rem 1rem 0rem',
				width:'100%',
				color:'rgba(0,0,0,0.6)',
				h2:{
					fontSize: '1rem',
					margin: '0.2rem',
				},
				p:{
					fontSize: '0.9rem',
					margin: '0.2rem'
				}
			},
		},
	},
	'@media only screen and (max-width: 768px)':{
		width: '100%',
		'.body':{
			flexDirection: 'column',
		},
		'.help-links':{
			width: '100% !important',
			borderLeft: 'none !important',
			borderTop: '0.2rem solid #007CC1'
		}
	}
});

const VerificationSentView = ({setView, ...props}) => {

	return(
		<div className={'email-input'} css={StyledEmailInputPage}>
			<div className={'body'}>
				<VerificationSentNotice/>
				<div className={'help-links'}>
					<BackLink onClick={() => setView('Landing')}/>
					<HelpDeskLink/>
				</div>
			</div>
			<AdminLoginLink onClick={() => setView('Admin')}/>
		</div>
	)
};


export default VerificationSentView;
