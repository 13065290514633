/** @jsx jsx **/
import { jsx } from "@emotion/core";
import {defaultStrings} from '../../../lib/i18n/en';
import dayjs from 'dayjs';

const StyledEmployerSelect = () => ({
	width: '100%',
	margin: 'auto',
	background: 'rgba(0,0,0,0.2)',
	padding: '0.5rem',
	borderRadius: '0.5rem',
	'.employer':{
		width: '100%',
		display: 'flex',
    flexDirection: 'column',
		background: '#007CC1',
		borderRadius: '0.5rem',
		fontWeight: '600',
		color: "white",
		padding: '1rem',
		margin: '0.3rem 0',
		cursor: 'pointer',
		'.name':{
			width: '100%',
		},
		'.date':{
			width: '100%',
			marginTop: '0.3rem',
      fontSize: '0.8rem',
      fontWeight: '300'
		}
	},
	'.selected':{
		background: '#1F67C1'
	}
});

const EmployerSelect = ({
  required,
  setValue,
  employerList,
  name,
	value
}) => {
  const {mostRecentActivity} = defaultStrings;
  const onValueChange = value => {
    if (required && value === "") {
      setValue(name, value, false);
    } else {
      setValue(name, value, true);
    }
  };

  const formatDate = (date) =>{
    return dayjs(date).format('MMMM D, YYYY')
  };

  return (
    <div className={"employers"} css={StyledEmployerSelect}>
      {employerList.map(employer => (
        <div key={employer.name} className={employer.name === value.name? "employer selected": "employer" } tabIndex={1} onClick={() => onValueChange(employer)}>
          <div className={"name"}>{employer.name}</div>
	        {employer.date !== ''? <div className={'date'}>{mostRecentActivity}{formatDate(employer.date.date)}</div> : null }
        </div>
      ))}
      <div className={value === 'none'? 'employer selected' : 'employer'} tabIndex={1} onClick={() => onValueChange('none')}>
	      <div className={"name"}>None of the Above</div>
      </div>
    </div>
  );
};

export default EmployerSelect;
