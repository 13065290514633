/** @jsx jsx **/
import { jsx } from "@emotion/core";
import TextInputBase
	from './TextInputBase';

const BadgeNumber = ({ required, setValue, name, ...props }) => {

	const onValueChange = (value) => {
	  if (/^\d\d+$/.test(value)){
	  	setValue(name, value, true);
	  }else{
	  	if(value === "" && !required){
			  setValue(name, value, true)
		  }else{
			  setValue(name, value, false)
		  }
	  }
  };

  return (
    <TextInputBase required={required} type={'text'} onValueChange={onValueChange} name={name} {...props} />
  );
};

export default BadgeNumber;
