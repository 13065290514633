/** @jsx jsx **/
import { jsx } from '@emotion/core';
import icons from '../../lib/icons';

const Style = {
  path: {
    fill: '#fff'
  }
};

const Icon = ({ name, className, onClick, tabIndex }) => (
  <svg
    tabIndex={tabIndex}
    className={`${name} icon ${className}`}
    onClick={onClick}
    viewBox='0 0 24 24'
    css={Style}
  >
    <path d={icons[name]} />
  </svg>
);

Icon.defaultProps = {
  className: '',
  onClick: () => {},
  tabIndex: null
};

export default Icon;
