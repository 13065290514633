/** @jsx jsx **/
import { jsx } from "@emotion/core";
import { useState } from "react";
import Form
	from './Form';
import { defaultStrings } from '../../lib/i18n/en';
import BasicLogin
	from '../../actions/basicLogin';
import PostLogin
	from '../../actions/postLogin';

const AdminLoginForm = () => {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const {username, password, adminWelcome, loginCredentialsDoNotMatchRecords, enterLearningPortal} = defaultStrings;
	const [formInputs, setFormInputs] = useState([
		{
			name: username,
			type: "text",
			value: "",
			valid: false,
			disabled: false,
			required: true
		},
		{
			name: password,
			type: "password",
			value: "",
			valid: false,
			disabled: false,
			required: true
		},
	]);

	const onComplete = () => {
		setLoading(true);
		setMessage('');
		BasicLogin(formInputs[0].value, formInputs[1].value).then((response) => {
			setLoading(false);
			if(response.ok){
				PostLogin();
			}else{
				setMessage(loginCredentialsDoNotMatchRecords);
			}
		})
	};

  return (
    <Form formInputs={formInputs} setFormInputs={setFormInputs} submitText={enterLearningPortal} action={onComplete} loading={loading} description={adminWelcome} message={message}/>
  );
};

export default AdminLoginForm;
