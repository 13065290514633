/** @jsx jsx **/
import { jsx } from "@emotion/core";
import {
  useState
} from "react";
import Icon from "../../lib/Icon";

const StyledDropDown = (hasFocus, hasValue, isValid, required) => ({
	display: "flex",
	width: "100%",
	position: "relative",
	fontSize: "1rem",
	paddingBottom: '2.2rem',
	color: "rgba(0,0,0,0.3)",
	select: {
		fontSize: "1.3rem",
		borderRadius: "0.5rem",
		background: "#d4d4d4",
		border: "0.07rem solid transparent",
		padding: "0.5rem",
		transition: "border-color 150ms linear",
		width: '90%',
		margin: "auto",
		"&:focus": {
			outline: "none",
			borderBottom: "0.1rem solid #007CC1"
		},
		"&:disabled":{
			background: '#b0b0b0',
			cursor: 'no-drop'
		}
	},
	label:{
		width: '100%',
		p:{
			position: "absolute",
			left: "0.5rem",
			top: hasFocus || hasValue || required? "-2.2rem" : "-0.7rem",
			color: hasFocus || hasValue ? "#007CC1" : "rgba(0,0,0,0.6)",
			fontSize: hasFocus || hasValue || required ? "1rem" : "1.3rem",
			transition: "all 150ms linear",
			pointerEvents: 'none'
		}
	},
	".indicator": {
		position: "absolute",
		lineHeight: "3.4rem",
		right: '0.5rem',
		svg: {
			height: "1.5rem",
			width: "1.5rem",
			path: {
				fill: isValid? 'green': 'red',
			}
		}
	}
});

const DropDownBase = ({ required, disabled, value, onValueChange, valid, name, options, loading}) => {
	const [hasFocus, setHasFocus] = useState(false);

	return (
		<div className={'drop-down-select-base'} css={StyledDropDown(hasFocus, value !== "", valid, required)}>
			<label>
				<p>{name + (required? '*': '')}</p>
				<select
					className={"drop-down-input"}
					name={name}
					value={value}
					onFocus={() => setHasFocus(true)}
					onBlur={() => setHasFocus(false)}
					required={required}
					disabled={loading || disabled}
					onChange={e => {
						onValueChange(e.target.value)
					}}
				>
					{options.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
				</select>
			</label>
			<div className={"indicator"}>
				<Icon name={ valid? "valid": 'invalid'} />
			</div>
		</div>
	);
};

export default DropDownBase;
