/** @jsx jsx **/
import { jsx } from "@emotion/core";
import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";
import TextInput from "./TextInput";
import MonthSelect from "./MonthSelect";
import DaySelect from "./DaySelect";
import CompanySelect
	from './CompanySelect';
import BadgeNumber
	from './BadgeNumber';
import EmployerSelect
	from './EmployerSelect';
import PasswordStrictInput
  from './PasswordStrictInput';

const Input = ({ input, ...props }) => {
  const inputTypes = {
    email: EmailInput,
    password: PasswordInput,
    passwordStrict: PasswordStrictInput,
    text: TextInput,
    month: MonthSelect,
    day: DaySelect,
	  company: CompanySelect,
	  badgeNumber: BadgeNumber,
	  employer: EmployerSelect
  };
  const Inputs = inputTypes[input.type];
  return <Inputs {...input}  {...props} />;
};

export default Input;
