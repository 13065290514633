const existingUserCheck = (emailInput, setMessage, internalError) =>
	fetch("/includes/modules/FM_LoginApis/cnrlContractor/existingUserCheck.php", {
		method:'POST',
		body: emailInput.value
	}).then(res =>{
		if(res.ok){
			return res.json();
		}else{
			setMessage(internalError)
		}
	});

export default existingUserCheck;
