/** @jsx jsx **/
import { jsx } from "@emotion/core";
import { useState } from "react";
import Form from "./Form";
import existingUserCheck from "../../actions/existingUserCheck";
import { defaultStrings } from '../../lib/i18n/en';
import PrepareUserDetails
	from '../../lib/prepareUserDetails';

const EmailForm = ({ setView, setPreviousDetails, previousDetails}) => {
	const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
	const {email, emailInfo, proceedToNextStep, cnrlWelcome, internalError} = defaultStrings;

  const [formInputs, setFormInputs] = useState([
    {
      name: email,
      type: "email",
      value: "",
      valid: false,
      disabled: false,
      required: true,
	    info: emailInfo
    }
  ]);

  const onComplete = () => {
    setLoading(true);
    existingUserCheck(formInputs[0], setMessage, internalError).then((response) => {
      setLoading(false);
      setPreviousDetails({...previousDetails, ...PrepareUserDetails(formInputs)});
      if(response !== undefined){
        if(response){
          setView("Password");
        }else{
          setView("VerificationSent");
        }
      }
    });
  };

  return (
    <Form
      formInputs={formInputs}
      setFormInputs={setFormInputs}
      submitText={proceedToNextStep}
      action={onComplete}
      loading={loading}
      description={cnrlWelcome}
      message={message}
    />
  );
};

export default EmailForm;
