/** @jsx jsx **/
import { jsx } from "@emotion/core";
import TextInputBase
	from './TextInputBase';

const EmailInput = ({ required, setValue, name, ...props }) => {

	const onValueChange = (value) => {
	  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,15})+$/.test(value)){
	  	setValue(name, value, true);
	  }else{
	  	if(value === "" && !required){
			  setValue(name, value, true)
		  }else{
			  setValue(name, value, false)
		  }
	  }
  };

  return (
    <TextInputBase required={required} type={'email'} onValueChange={onValueChange} name={name} {...props} />
  );
};

export default EmailInput;
