/** @jsx jsx **/
import { jsx } from '@emotion/core';

const StyledButton = () => ({
	display: 'flex',
	padding: '0.5rem 0',
	button:{
		fontWeight: '600',
		margin: '1rem auto',
		background: '#007CC1',
		border: 'none',
		color: 'white',
		padding: '0.7rem 3rem',
		borderRadius: '0.3rem',
		fontSize: '0.9rem',
		cursor: 'pointer',
		'&:hover':{
			background: '#035685'
		},
		'&:disabled':{
			background: 'grey'
		}
	}

});

const SubmitButton = ({text, disabled, action, loading}) => {
	const onClick = (e) => {
		e.preventDefault();
		action();
	};

	return(
		<div className={'submit-button'} css={StyledButton}>
			<button disabled={loading||disabled} onClick={e => onClick(e)}>{text}</button>
		</div>
	)
};


export default SubmitButton;
