/** @jsx jsx **/
import { jsx } from "@emotion/core";
import { useState } from "react";
import TwoColumnForm from "./TwoColumnForm";
import CheckUserDetails from "../../actions/checkUserDetails";
import PrepareUserDetails from "../../lib/prepareUserDetails";
import { defaultStrings } from "../../lib/i18n/en";

const UserDetailsForm = ({ setView, previousDetails, setPreviousDetails }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const {
    firstName,
    lastName,
    birthMonth,
    birthDay,
    email,
    alternateEmail,
    badgeNumber,
    currentEmployer,
    password,
    confirmPassword,
    badgeNumberInfo,
    passwordsDoNotMatch,
    userDetailsWelcome,
    submit,
    internalError,
    passwordMinimumLength
  } = defaultStrings;

  const [formInputs, setFormInputs] = useState([
    {
      name: firstName,
      type: "text",
      value: "",
      valid: false,
      disabled: false,
      required: true
    },
    {
      name: lastName,
      type: "text",
      value: "",
      valid: false,
      disabled: false,
      required: true
    },
    {
      name: birthMonth,
      type: "month",
      value: previousDetails.monthOptions[0]
        ? previousDetails.monthOptions[0].id
        : "",
      valueId: "",
      valid: true,
      disabled: false,
      required: true
    },
    {
      name: birthDay,
      type: "day",
      value: previousDetails.dayOptions[0]
        ? previousDetails.dayOptions[0].id
        : "",
      valueId: "",
      valid: true,
      disabled: false,
      required: true
    },
    {
      name: email,
      type: "text",
      value: previousDetails["Email"] ? previousDetails["Email"] : "",
      valid: true,
      disabled: true,
      required: false
    },
    {
      name: alternateEmail,
      type: "email",
      value: "",
      valid: true,
      disabled: false,
      required: false
    },
    {
      name: badgeNumber,
      type: "badgeNumber",
      value: "",
      valid: true,
      disabled: false,
      required: false,
      info: badgeNumberInfo
    },
    {
      name: currentEmployer,
      type: "company",
      value: "",
      valueId: "",
      valid: false,
      disabled: false,
      required: true
    },
    {
      name: password,
      type: "passwordStrict",
      value: "",
      valid: false,
      disabled: false,
      required: true,
      info: passwordMinimumLength
    },
    {
      name: confirmPassword,
      type: "passwordStrict",
      value: "",
      valid: false,
      disabled: false,
      required: true
    }
  ]);

  const onComplete = () => {
    const pass = formInputs.find(input => input.name === password);
    const confPass = formInputs.find(input => input.name === confirmPassword);
    if (pass.value !== confPass.value) {
      setMessage(passwordsDoNotMatch);
    } else {
      setLoading(true);
      const preparedDetails = PrepareUserDetails(formInputs);
      CheckUserDetails(preparedDetails, setMessage, internalError).then(res => {
        if(res !== undefined){
          setPreviousDetails({
            ...previousDetails,
            ...preparedDetails,
            employers: res
          });
          setLoading(false);
          setView("Company");
        }
      });
    }
  };

  return (
    <TwoColumnForm
      formInputs={formInputs}
      setFormInputs={setFormInputs}
      submitText={submit}
      action={onComplete}
      loading={loading}
      description={userDetailsWelcome}
      monthOptions={previousDetails.monthOptions}
      dayOptions={previousDetails.dayOptions}
      companyList={previousDetails.companyList}
      message={message}
    />
  );
};

export default UserDetailsForm;
