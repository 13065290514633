/** @jsx jsx **/
import { jsx } from "@emotion/core";

const StyledLoading = () => ({
  borderTop: "1.1em solid #007CC1",
  borderRight: "1.1em solid #007CC1",
  borderBottom: "1.1em solid #007CC1",
  borderLeft: "1.1em solid #fafafa",
  animation: "spin 1.1s infinite linear",
  position: "absolute",
  left: "calc(50% - 3.75rem)",
  top: "calc(50% - 3.75rem)",
  borderRadius: "50%",
  width: "5em",
  height: "5em",
  margin: "auto",
  "&.isLoading": {
    display: "block"
  },
  "@keyframes spin": {
    "0%": {
      backfaceVisibility: "hidden",
      transform: "rotate(0deg) scale(1, 1)"
    },
    "100%": {
      backfaceVisibility: "hidden",
      transform: "rotate(360deg) scale(1, 1)"
    }
  },
  "&:after": {
    borderRadius: "50%",
    width: "5em",
    height: "5em"
  }
});

const Loading = () => {
  return <div className={"loading-circle"} css={StyledLoading} />;
};

export default Loading;
