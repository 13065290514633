/** @jsx jsx **/
import { jsx } from '@emotion/core';
import { defaultStrings } from '../../lib/i18n/en';

const CompanyNotListedLink = () => {
	const {havingTroubleFindingCompany, companyHelpInformation, companyHelpInformation2} = defaultStrings;
	return(
		<div className={'help-desk help'}>
			<h2>{havingTroubleFindingCompany}</h2>
			<p>{companyHelpInformation} <a href={'mailto:Training.Systems@cnrl.com?subject=CNRL Contractor Portal'} target="_blank" rel='noopener noreferrer'>Training.Systems@cnrl.com</a> {companyHelpInformation2}</p>
		</div>
	)
};

export default CompanyNotListedLink;
