const PrepareUserDetails = (formInputs) => {
	const preparedDetails = {};
	formInputs.forEach((input) => {
		if(input.type === 'company' || input.type === 'employer'){
			preparedDetails[input.name] = {companyName: input.value.name, companyId: input.value.id}
		}else{
			preparedDetails[input.name] = input.value;
		}
	});
	return preparedDetails
};

export default PrepareUserDetails;
