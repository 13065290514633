/** @jsx jsx **/
import { jsx } from '@emotion/core';
import { defaultStrings } from '../../lib/i18n/en';
import ResetPassword
	from '../../actions/resetPassword';

const ForgotPasswordLink = ({username}) => {
	const {forgotYourPassword, forgotHelp, resetYourPassword, forgotHelp2} = defaultStrings;

	const onClick = () =>{
    ResetPassword(username).then( () => {alert("Password Reset Email has been sent")});
  };

	return(
		<div className={'help-desk help'}>
			<h2>{forgotYourPassword}</h2>
			<p>{forgotHelp} <a href={'javascript:;'} onClick={() => onClick()}>{resetYourPassword}</a> {forgotHelp2}</p>
		</div>
	)
};


export default ForgotPasswordLink;
