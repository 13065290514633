export const defaultStrings = {
  email: "Email",
  emailInfo: "Use a personal email address if available",
  proceedToNextStep: "Proceed to Next Step",
  cnrlWelcome:
    "Welcome to the Canadian Natural Contractor E-Learning portal. Please enter a valid email address and proceed to the next step",
  enterPortal: "Enter Learning Portal",
  welcomeBack:
    "Welcome back to the Canadian Natural Contractor E-Learning Portal. Please enter your password to continue into the learning portal.",
  username: "Username",
  password: "Password",
  adminWelcome:
    "Welcome back. Please enter your administrative credentials to gain access to the learning portal",
  invalidCredentials:
    "Provided Login Credentials do not match our records. Please Try Again",
  firstName: "First Name",
  lastName: "Last Name",
  birthMonth: "Birth Month",
  birthDay: "Birth Day",
  alternateEmail: "Alternate Email",
  badgeNumber: "Badge Number",
  currentEmployer: "Current Employer",
  confirmPassword: "Confirm Password",
  badgeNumberInfo: "Multi-digit number found at bottom of badge",
  passwordsDoNotMatch:
    "Please ensure the Password and Confirm Password Fields Match",
  userDetailsWelcome:
    "In order to get you setup to use this site we will require more details from you. Please fill in the following details and submit them to proceed into the site",
	submit: 'Submit',
	previousEmployerDescription: 'If one or more of the presented options match a former employer select the most recent match and that account will be updated with the provided information. If no options match select "None of the Above" and a new account will be created for you',
	confirm: 'Confirm',
	previousEmployer: 'Previous Employer',
	createAnEmail: 'Create an email address',
	recommendedEmailPartner: 'If you do not already have an active email address or wish to create one we recommend using',
	gmail: 'Gmail',
	recommendedEmailPartnerPart2: ' or ',
	outlook: 'Outlook',
	goToAdminLogin: 'Go to Admin Login',
	back: 'Back',
	whatsMyBadgeNumber: 'What\'s my Badge Number?',
	badgeNumberLocation: 'Your badge Number is located near the bottom of your badge above your company name ',
	havingTroubleFindingCompany: 'Having trouble finding your company?',
	companyHelpInformation: 'Contact ',
	companyHelpInformation2: 'to have it added to the list.',
	forgotYourPassword: 'Forgot Your Password?',
	forgotHelp: 'Click to',
	forgotHelp2: 'and have further instructions sent to your email',
	resetYourPassword: 'Reset Your Password',
	helpDeskIntro: 'Having Problems?',
	helpDeskInfo: 'Visit the',
	cnrlHelpDesk: 'Canadian Natural Help Desk',
	verificationSentNotice: 'A verification email has been sent to the provided address. Please follow the steps provided in the email to continue into the Contractor E-Learning portal',
  verificationSentNotice1: 'Be patient it can take some time for the system email to reach your inbox. If after a while you cannot find the email in your inbox, check your SPAM folder',
  verificationSentNotice2: 'If the email has not shown up after a while and is not located in your SPAM folder, click Back and try to reinput your email address',
	verificationInProgressNotice: 'We are in the process of confirming the verification of your email. Please wait while this verification completes.',
  contractorELearning: 'Contractor E-Learning Portal',
  systemSupport: 'Please contact ',
  call: ' or call ',
  icomSupportEmail: 'servicedesk@icomproductions.ca',
  icomPhoneNumber: '1-877-275-7190',
  mostRecentActivity: 'Most recent date of found activity ',
  loginCredentialsDoNotMatchRecords: 'Provided login credentials do not match our records',
  enterLearningPortal: 'Enter Learning Portal',
  internalError: 'Internal Error Occurred',
  passwordMinimumLength: 'Minimum eight characters',
  flashPlayerNotDetected:'Flash Player Not Detected!',
  flashPlayerTextPreLink:'Flash is required for some features of this site to function properly. Please note flash is not supported by mobile browsers. If you are experiencing issues with Flash Player on this site, please follow the directions found ',
  flashPlayerLinkText: 'here',
  flashPlayerTextPostLink:' for your current browser.',
};
