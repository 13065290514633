/** @jsx jsx **/
import { jsx } from "@emotion/core";
import DropDownBase from "./DropDownSelectBase";
import { useEffect, useState } from "react";

const DaySelect = ({ required, setValue, name, dayOptions, ...props }) => {
  const [values, setValues] = useState(dayOptions);
  const onValueChange = value => {
    if (required && value.name === "") {
      setValue(name, value, false);
    } else {
      setValue(name, value, true);
    }
  };
  useEffect(
    () => {
      setValues(
        dayOptions.sort((a, b) => {
          return a.name - b.name;
        })
      );
    },
    [dayOptions]
  );

  return (
    <DropDownBase
      required={required}
      type={"email"}
      onValueChange={onValueChange}
      name={name}
      options={values}
      {...props}
    />
  );
};

export default DaySelect;
