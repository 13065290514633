/** @jsx jsx **/
import { jsx } from "@emotion/core";
import {
	useEffect,
	useRef,
	useState
} from 'react';
import Icon from "../../lib/Icon";

const StyledTextInput = (hasFocus, hasValue, isValid, loading) => ({
  display: "flex",
  width: "100%",
  position: "relative",
  fontSize: "1rem",
  paddingBottom: "2.2rem",
  color: "rgba(0,0,0,0.3)",
  input: {
    fontSize: "1.3rem",
    borderRadius: "0.5rem",
    background: "#d4d4d4",
    border: "0.07rem solid transparent",
    padding: "0.5rem 4.6rem 0.5rem 0.5rem",
    transition: "border-color 150ms linear",
    width: "90%",
    margin: "auto",
    "&:focus": {
      outline: "none",
      borderBottom: "0.1rem solid #007CC1"
    },
    "&:disabled":{
	    background: loading? "#b0b0b0" : "#d4d4d4" ,
	    cursor: loading? "no-drop" : "default"
    }
  },
  label: {
    width: "100%",
    p: {
      position: "absolute",
      left: "0.5rem",
      top: hasFocus || hasValue ? "-2.2rem" : "-0.7rem",
      color: hasFocus || hasValue ? "#007CC1" : "rgba(0,0,0,0.6)",
      fontSize: hasFocus || hasValue ? "1rem" : "1.3rem",
      transition: "all 150ms ease-in",
      pointerEvents: "none"
    }
  },
  ".indicator": {
    position: "absolute",
    lineHeight: "3.4rem",
    right: "0.5rem",
    svg: {
      height: "1.5rem",
      width: "1.5rem",
      path: {
        fill: isValid ? "green" : "red"
      }
    }
  },
  ".drop-options": {
    position: "absolute",
    width: "90%",
    top: "2.5rem",
    background: "white",
    boxShadow: "rgba(0,0,0,0.5) 0px 0.125rem 0.625rem 0px",
    zIndex: "5",
    color: "black",
    ".drop-option": {
      display: "flex",
      padding: "0.5rem",
      borderBottom: "0.1rem solid #007CC1",
      span: {
        width: "100%"
      },
      button: {
        margin: "auto",
        border: "none",
        backgroundColor: "#007cc1",
        color: "white",
        padding: "0.2rem 0.5rem",
        borderRadius: "0.3rem",
	      cursor: 'pointer',
      }
    }
  },
  '.change-btn':{
  	position: 'absolute',
	  top: '0.7rem',
	  right: '12%',
	  color: 'white',
	  backgroundColor: '#007cc1',
	  border: "none",
	  padding: "0.2rem 0.5rem",
	  borderRadius: "0.3rem",
	  cursor: 'pointer',
  }
});

const LookupSelectBase = ({
  required,
  onValueChange,
  valid,
  name,
  options,
	loading
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [dropOptions, setDropOptions] = useState([]);
  const [optionsSelected, setOptionSelected] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef(null);

  useEffect(
    () => {
      const refinedOptions = options.filter(option =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      const limitedOptions =
        refinedOptions.length > 6
          ? refinedOptions.slice(0, 6)
          : refinedOptions;
      setDropOptions(limitedOptions);
    },
    [searchValue]
  );

  const setValue = (e, option) => {
    e.preventDefault();
    setOptionSelected(option.name);
    setSearchValue("");
    onValueChange(option);
  };
  const unSetValue = (e) => {
  	e.preventDefault();
  	setOptionSelected(null);
  	onValueChange('');
  	setTimeout(() => {
		  inputRef.current.focus();
	  }, 10);

  };

  const onValueChanged  = (e) => {
    if(optionsSelected === null){
      setSearchValue(e.target.value)
    }else{
      //doNothing
    }
  };

  return (
    <div
      className={"text-input-base"}
      css={StyledTextInput(hasFocus, searchValue !== '' || optionsSelected !== null, valid, loading)}
    >
      <label>
        <p>{name + (required ? "*" : "")}</p>
        <input
	        ref={inputRef}
          className={"text-input"}
          type={"text"}
          name={name}
          value={optionsSelected !== null? optionsSelected : searchValue}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          required={required}
          disabled={ loading || optionsSelected !== null}
          onChange={e => onValueChanged(e)}
        />
      </label>
      {optionsSelected !== null ? (
        <button className={"change-btn"} onClick={(e) => unSetValue(e)}>Change</button>
      ) : null}
      {searchValue !== "" ? (
        <div className={"drop-options"}>
          {dropOptions.map(option => (
            <div className={"drop-option"} tabIndex={1} key={option.name}>
              <span>{option.name}</span>
              <button onClick={e => setValue(e, option)}>Select</button>
            </div>
          ))}
        </div>
      ) : null}
      <div className={"indicator"}>
        <Icon name={valid ? "valid" : "invalid"} />
      </div>
    </div>
  );
};

export default LookupSelectBase;
