/** @jsx jsx **/
import { jsx } from "@emotion/core";
import { useEffect } from "react";

const StyledBackground = () => ({
  position: "absolute",
  top: "0",
  height: "100%",
  minHeight: "100vh",
  width: "100%",
  background: "linear-gradient(#007CC1, #fafafa)",
  zIndex: "0",
  overflow: 'hidden',
  ".white-top": {
    background:
      "url('/orgresources/1/FM_Guidebook_Resources/back.JPG') 50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "16.5rem",
    width: "100%",
    borderBottom: "0.2rem solid #007CC1"
  },
  '@media only screen and (max-width: 768px)':{
    '.canvas':{
      display: 'none',
    }
  }
});

const Background = () => {
  useEffect(() => {

    const rb = (min, max) => min + Math.random() * (max - min);

    const createPoint = () => ({
      x: rb(0, w),
      y: rb(0, h),
      vx: rb(-1, 1),
      vy: rb(-1, 1),
      r: 3,
      a: 1
    });

    const render = ({ x, y, r, a }) => {
      ctx.save();
      ctx.fillStyle = '#FFF';
      ctx.beginPath();
      ctx.arc(x, y, r, 0, Math.PI * 2);
      ctx.closePath();
      ctx.globalAlpha = a;
      ctx.fill();
      ctx.restore();
    };

    const drawline = ( x, x1, y, y1, t) => {
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x1, y1);
      ctx.strokeStyle = 'rgba(255,255,255,' + t + ')';
      ctx.stroke();
      ctx.restore();
    };

    const canvas = document.getElementById('play-thing');
    const ctx = canvas.getContext('2d');

    let w;
    let h;
    const f = [];

    const resize = () => {
      w = window.innerWidth;
      h = window.innerHeight;

      canvas.width = w;
      canvas.height = h;

      f.length = Math.floor((w * h) / 25000);
      f.fill(createPoint());
    };

    resize();
    window.addEventListener('resize', resize);

    const draw = () => {
      ctx.clearRect(0, 0, w, h);
      f.forEach((snowflake, i, arr) => {
        const { x, y, vx, vy} = snowflake;
        if(y < -10 || y > h + 10 || x < -10 || x > w + 10){
          arr[i] = createPoint();
        }else{
          arr[i].x += vx;
          arr[i].y += vy;
        }
        render(snowflake);

        f.forEach(flake =>{
          if(Math.abs(flake.x - snowflake.x) <170 && Math.abs(flake.y - snowflake.y) < 170){
            const d = (Math.abs(flake.x - snowflake.x)) + (Math.abs(flake.y - snowflake.y) < 170);
            const t = 1 - d/120;
            drawline(flake.x, snowflake.x, flake.y, snowflake.y, t);
          }
        })
      });
      requestAnimationFrame(draw);
    };
    requestAnimationFrame(draw);
  }, []);

  return (
    <div className={"background"} css={StyledBackground}>
      <div className={"white-top"} />
      <canvas id={"play-thing"} className={'canvas'} width={"100vw"} height={"100vh"} />
    </div>
  );
};

export default Background;
