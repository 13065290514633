/** @jsx jsx **/
import { jsx } from "@emotion/core";
import LookupSelectBase
	from './LookupSelectBase';

const CompanySelect = ({ required, setValue, name, companyList, ...props }) => {
	const onValueChange = (value) => {
		if(required && value === ''){
			setValue(name, value, false);
		}else{
			setValue(name, value, true);
		}
	};

	return (
		<LookupSelectBase required={required} onValueChange={onValueChange} name={name} options={companyList} {...props} />
	);
};

export default CompanySelect;
