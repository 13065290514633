/** @jsx jsx **/
import { jsx } from "@emotion/core";
import { useState } from "react";
import Form from "./Form";
import { defaultStrings } from "../../lib/i18n/en";
import mergeUsers from "../../actions/mergeUsers";
import PrepareUserDetails from "../../lib/prepareUserDetails";

const CompanySelectForm = ({
  previousDetails
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('')
  const {
    previousEmployer,
    previousEmployerDescription,
    confirm,
    internalError
  } = defaultStrings;
  const [formInputs, setFormInputs] = useState([
    {
      name: previousEmployer,
      type: "employer",
      value: "",
      valid: false,
      disabled: false,
      required: true
    }
  ]);

  const onComplete = () => {
    setLoading(true);
    mergeUsers({ ...previousDetails, ...PrepareUserDetails(formInputs) }, setMessage, internalError).then(() => {
      setLoading(false);
    });
  };

  return (
    <Form
      employerList={previousDetails.employers}
      formInputs={formInputs}
      setFormInputs={setFormInputs}
      submitText={confirm}
      action={onComplete}
      loading={loading}
      description={previousEmployerDescription}
      message={message}
    />
  );
};

export default CompanySelectForm;
