/** @jsx jsx **/
import { jsx } from "@emotion/core";
import DropDownBase
	from './DropDownSelectBase';

const MonthSelect = ({ required, setValue, name, monthOptions, ...props }) => {
	const onValueChange = (value) => {
		if(required && value.name === ''){
			setValue(name, value, false);
		}else{
			setValue(name, value, true);
		}
	};

	return (
		<DropDownBase required={required} type={'email'} onValueChange={onValueChange} name={name} options={monthOptions} {...props} />
	);
};

export default MonthSelect;
