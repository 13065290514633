import PostLogin from "./postLogin";

const CheckUserDetails = (userDetails, setMessage, internalError) =>
	fetch("/includes/modules/FM_LoginApis/cnrlContractor/checkUserDetails.php", {
		method:'POST',
		body: JSON.stringify(userDetails)
	}).then(response => {
		if (response.ok) {
			if(response.headers.get('Content-Type') === "application/json"){
				return response.json();
			}else{
				PostLogin();
			}
		} else {
			setMessage(internalError);
		}
	});

export default CheckUserDetails;
