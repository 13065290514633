const setValueHelper = (formInputs, setFormInputs) => (name, value, valid) => {
	const index = formInputs.findIndex(value => value.name === name);
	const newValue = { ...formInputs[index], value: value, valid:valid };
	if (index > -1) {
		const newArray = [
			...formInputs.slice(0, index),
			newValue,
			...formInputs.slice(index + 1)
		];
		setFormInputs(newArray);
	}
};

export default setValueHelper;
