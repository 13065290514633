/** @jsx jsx **/
import { jsx } from "@emotion/core";
import TextInputBase
	from './TextInputBase';

const PasswordStrictInput = ({ required, setValue, name, type, ...props }) => {
  const onValueChange = (value) => {
	 if(value.length > 7){
	 	setValue(name, value, true);
	 }else{
	 	setValue(name, value, false)
	 }
  };

  return (
   <TextInputBase required={required} type={'password'} onValueChange={onValueChange} name={name} {...props} />
  );
};

export default PasswordStrictInput;
