/** @jsx jsx **/
import { jsx } from '@emotion/core';
import Icon
	from '../lib/Icon';
import { defaultStrings } from '../../lib/i18n/en';

const StyledAdminLoginLink = () => ({
	position: 'absolute',
	fontSize: '1rem',
	right: '1rem',
	display: 'flex',
	lineHeight: '1.5rem',
	cursor: 'pointer',
	bottom: '0.5rem',
	'&:hover':{
		color: '#007CC1',
		'.icon':{
			path: {
				fill:'#007CC1',
			}
		}
	},
	'.icon':{
		height: '1.5rem',
		width: '1.5rem',
		path: {
			fill: 'black'
		}
	}
});

const AdminLoginLink = ({onClick}) => {
	const {goToAdminLogin} = defaultStrings;
	return(
		<div className={'admin-login'} css={StyledAdminLoginLink} onClick={() => onClick()}>
			<div className={'text'}>{goToAdminLogin}</div>
			<Icon name={'arrow-right'}/>
		</div>
	)
};


export default AdminLoginLink;
